import React, { useState, useCallback, useEffect, useContext } from 'react';
import algoliasearch from 'algoliasearch';
import useFocus from './useFocus';
import Results from './Results';
import SearchContext, { SearchConsumer } from './SearchContext';

const Search = props => {
    const client = algoliasearch('Y99U29HQNZ', '9a9b18602fbfdf83c389d77b6b9fab9b');
    const index = client.initIndex('magento2_default_products');
    const [results, setResults] = useState([]);
    const [query, setQuery] = useState('');
    const { closeSearch, openSearch } = useContext(SearchContext);

    const handleSearch = event => {

        index.search(event.target.value).then(({ hits }) => {
            setResults(hits);
            openSearch();
          });
    };

    const escFunction = useCallback(event => {
        if (event.keyCode === 27) {
            closeSearch();
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);

        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, []);
    const [inputRef, setInputFocus] = useFocus();

    return (
        <SearchConsumer>
            {({ SearchOpen, closeSearch }) => {
                if (SearchOpen) {
                    setTimeout(() => {
                        setInputFocus();
                    }, 400);
                }
                return (
                    <div className={`searchContainer ${SearchOpen ? 'active' : ''}`}>
                        <i className="fas fa-search" />
                        <input id="searchQuery" type="text" onChange={handleSearch} placeholder="Type search query" ref={inputRef} />
                        <button type="button" className="menu_trigger is-active" onClick={() => closeSearch()}>
                            <span>&nbsp;</span>
                        </button>
                        <Results query={query} results={results} searchopen={SearchOpen} />
                    </div>
                );
            }}
        </SearchConsumer>
    );
};

export default Search;
