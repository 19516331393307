import React from 'react';

const ColourOption = (props) => {
  const { options, handle } = props;
  return (
    <select name="colour_option" onChange={handle}>
        <option key="0" value="">Select option</option>
      {options.map((colour_opt) => <option key={colour_opt} value={colour_opt}>{colour_opt}</option>) }
    </select>
  )
}

export default ColourOption
