import React from 'react';
import ResultItem from './ResultItem';

const Results = props => {
    const { query, results, searchopen } = props;
    return (
        <div className="searchResults">
            <div className={`searchResultsOverlay ${results.length >= 1 && searchopen === true ? 'active' : ''}`} />
            {results.length >= 1 && searchopen === true ? (
                <div className="resultsContainer" style="display: flex; flex-wrap: wrap;">
                    {results.map(hit => 
                        <ResultItem hit={hit} />
                    )}
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default Results;