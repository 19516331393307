import React, {useContext} from 'react';
import LoadingContext, { LoadingConsumer } from './LoadingContext';

const AddToCart = (props) => {
    const { label, handle } = props;
    //const { startLoading, stoploading, loadingCart } = useContext(LoadingContext);

    return (
        <LoadingConsumer>
            {
                ({ loadingCart }) => {
                    return(
                        <button onClick={handle}>
                            {label}
                        </button>
                    )
            }}
        </LoadingConsumer>
    );
}

export default AddToCart
