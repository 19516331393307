import React, { createElement } from 'react';
import Search from './components/Search'

function App() {
  return (
    <div className="app-container">
      <Search />
    </div>
  );
}

export default App;