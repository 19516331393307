import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import SearchContext from './components/Search/SearchContext';
import LoadingContext from './components/Search/LoadingContext';


class Checkout extends React.Component {
    constructor() {
        super();
        this.state = {
            SearchOpen: false,
            loadingCart: false,
            openSearch: () => {
                this.setState({ SearchOpen: true });
            },
            closeSearch: () => {
                this.setState({ SearchOpen: false });
            },
            startLoading: () => {
                this.setState({ loadingCart: true });
            },
            stopLoading: () => {
                this.setState({ loadingCart: false });
            }
        };
    }

    render() {
        return (
            <SearchContext.Provider value={this.state}>
                <LoadingContext.Provider value={this.state}>
                  <App />
                </LoadingContext.Provider>
            </SearchContext.Provider>
        );
    }
}

let RootElement = document.getElementById('react-search');

ReactDOM.render(<Checkout />, RootElement);
