import React, {useRef, useContext} from 'react';
import ColourOptions from './ColourOptions';
import AddToCart from "./AddToCart";
import axios from 'axios';


const ResultItem = props => {

    const { name, image_url, price, sku, url, colour_option  } = props.hit;
    let options, selectedSku, optionPath;

    const [loading, setLoading] = React.useState('Add to cart');
    const [selectedOption, setSelectedOption] = React.useState(0);

    const handleButton = () => {
        setLoading('Adding to cart');
        selectedSku = Array.isArray(sku) ? sku.slice(0, 1).shift() : sku;
        optionPath = selectedOption ? `/colour_option/` + selectedOption : '';

        axios.get(`/product/addtocart/index/sku/` + selectedSku + optionPath)
        .then(res => {
            if(res.data.statusCode === 200) {
                setLoading('Added!');
            } else {
                setLoading('Error');
            }
        }).catch(res => {
            setLoading('Error');
        });
        setTimeout(() => { setLoading('Add to cart'); }, 3000)
    }

    const handleOptionSelect = props => {
        setSelectedOption(props.target.value);
    }

    if (colour_option && colour_option.length) {
      options = <ColourOptions options={colour_option} handle={handleOptionSelect} />
    }

    return (
        <div className="searchItem" style="width:25%;">
            <a href={url}>
              <img src={image_url} />
              <span className='name'>{ name }</span>
              <span className='price'>{ price.GBP.default_formated }</span>
            </a>
            {options}
            <AddToCart handle={handleButton} label={loading} />
        </div>
    );
};

export default ResultItem;
